import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Cardstyles.scss'
import { Grid } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash'


export default function SimpleCard({ article }) {
    return (
        <Card>
            <CardContent>
                <div className="popular-root">
                    <h2>Popular Article</h2>
                </div>
                {
                    !_.isEmpty(article) && article.map((val, i) => {
                        return (
                            <div key={i} className="popular-content">
                                <Grid container spacing={1}>
                                    <Grid item xs={2} sm={2}>
                                        <h2>{i < 10 ? `0${i + 1}` : i + 1}</h2>
                                    </Grid>
                                    <Grid item xs={10} sm={10}>
                                        <h4>{val.title}</h4>
                                        <p>By <span>{val.author}</span> {moment(val.date).format('ll')}</p>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    })
                }
            </CardContent>
        </Card>
    );
}
