import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import './TagsStyles.scss'

const useStyles = makeStyles({
    root: {
        marginTop: '40px'
    },
    btRoot: {
        borderRadius: 20,
        fontSize: "10px",
        marginLeft: '5px',
        marginTop: '5px'
    }
});


export default function Tags({ tags }) {
    const classes = useStyles();
    const [allTags, setAllTags] = useState([])
    useEffect(() => {
        let temp = [];
        tags.forEach((val) => {
            val.tags.forEach((tags, i) => {
                if (temp.indexOf(tags) === -1) {
                    temp = [tags, ...temp]
                }
            })
        })
        setAllTags(temp)
    }, [tags])
    return (
        <Card className={classes.root}>
            <CardContent>
                <div className="popular-root">
                    <h2>Tags</h2>
                </div>
                {allTags.map((val, index) => {
                    return (
                        <Button key={index} className={classes.btRoot} variant="outlined" color="primary">
                            {val}
                        </Button>
                    )
                })
                }
            </CardContent>
        </Card>
    );
}
