import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './ImgMediaStyles.scss';
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    marginBottom: '50px'
  },
  h2: {
    fontSize: "24px",
    fontWeight: 900,
    letterSpacing: "0.48px",
    textAlign: "left",
    color: "#454545"
  },
  p: {
    textAlign: "left",
    color: "#4b4b4b",
    fontSize: "16px"
  },
  span: {
    textAlign: "left",
    color: "#4b4b4b",
    fontSize: "16px",
    marginTop: '15px',
    whiteSpace: 'nowrap'
  },
  btRoot: {
    borderRadius: 20,
    fontSize: "10px",
    marginLeft: '5px'
  }
});

export default function ImgMediaCard({ image, title, summary, author, tags }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} >
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="400px"
          image={image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography className={classes.h2} gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography className={classes.p} variant="body2" color="textSecondary" component="p">
            {summary}
          </Typography>
          <div className="author">
            <p>By <span>{author}</span> on 9 Mar, 2020</p>
          </div>
          {
            !_.isEmpty(tags) && tags.map((val, index) => {
              return (
                <Button key={index} className={classes.btRoot} variant="outlined" color="primary">
                  {val}
                </Button>
              )
            })
          }
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
