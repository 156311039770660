import { useState, useEffect } from 'react'
import firebase from "gatsby-plugin-firebase"

export default function useFirestore(collection, schemaName) {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        const unsub = firebase.firestore().collection(collection)
            .where('_fl_meta_.schema', '==', schemaName)
            .onSnapshot((snap) => {
                let documents = [];
                snap.forEach(doc => {
                    documents.push({ ...doc.data(), id: doc.id })
                });
                setDocs(documents);
            });

        return () => unsub();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collection]);
    return docs;
}
